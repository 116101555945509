<template>
  <v-dialog max-width="800" :value="show">

    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('update:show', false)">
        <v-icon class="font-weight-bold">{{icons.mdiClose}}</v-icon>
      </v-btn>
      <v-toolbar-title class="text-h5 white--text">{{formTitle}}</v-toolbar-title>
    </v-toolbar>

    <v-card>
      <v-card-title></v-card-title>
      <v-card-text class="pt-3">
        <v-form>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                label="Name"
                placeholder="Name"
                v-model="form.name"
                :error-messages="errors.name"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                outlined
                dense
                type="number"
                prefix="₹"
                label="Per Unit"
                placeholder="Per Unit"
                v-model="form.per_unit"
                :error-messages="errors.per_unit"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                label="For Festival Only"
                v-model="form.festival_only"
                :error-messages="errors.festival_only"
                class="mt-0"
                dense
              />
            </v-col>
          </v-row>
        </v-form>
        <v-divider/>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn color="secondary" outlined @click="$emit('update:show', false)">Cancel</v-btn>
        <v-btn color="primary" @click="save">Save</v-btn>
      </v-card-actions>

    </v-card>

    <v-snackbar v-model="snackbar.active" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar.active = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import {mdiClose} from '@mdi/js';

export default {
  data() {
    return {
      form: {
        id: null,
        name: '',
        per_unit: null,
        festival_only: false,
      },
      errors: {

      },
      snackbar: {
        active: false,
        message: null,
        timeout: -1,
        color: null
      },
      icons: {
        mdiClose,
      },
      loading: false
    }
  },
  props: {
    show: false,
    donationType: null,
  },
  computed: {
    formTitle() {
      return this.donationType && this.donationType.id ? 'Edit Donation Category' : 'Create Donation Category';
    }
  },
  watch: {
    show() {

      if (this.show && this.donationType && this.donationType.id) {

        this.form = {...this.donationType};
      } else if (!this.show) {

        this.form = {
          id: null,
          name: '',
          per_unit: null,
          festival_only: false,
        };
      }
    },
  },
  methods: {
    save() {

      this.loading = true;
      let apiURL = '/donation-types';
      if (this.form.id) apiURL += '/' + this.form.id;

      axiosBackend.post(apiURL, this.form)
        .then((response) => {

          this.$emit('update:show', false);
        })
        .catch((error) => {

          if (error.response.status === 422) {

            this.errors = error.response.data.errors;
          }
          this.snackbar = {
            active: true,
            color: 'error',
            timeout: 5000,
            message: error.response.data.message,
          };
        });
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <donation-type-form :show.sync="donationTypeFormStatus" :donation-type="editingDonationType"></donation-type-form>
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn icon outlined color="primary" @click="donationTypeFormStatus = true"><v-icon v-html="icons.mdiPlus" /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title><h4>Donation Categories</h4></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="8" md="10">
                <v-text-field
                  dense
                  outlined
                  clearable
                  label="Search"
                  :prepend-inner-icon="icons.mdiMagnify"
                  class="app-bar-search flex-grow-0"
                  :value="selectedFilters.search"
                  @input="debounceSearch"
                />
              </v-col>
              <v-col cols="12" sm="4" md="2">
                <v-select
                  outlined
                  dense
                  deletable-chips
                  label="Type"
                  placeholder="Type"
                  :items="[{text: 'All', value: null}, {text: 'For Festival Only', value: 1}, {text: 'Non Festival', value: 0}]"
                  :value="selectedFilters.type"
                  @change="updateFilter($event,'type')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="donationTypes"
                  disable-sort
                  :hide-default-footer="!donationTypes.length"
                  :items-per-page="15"
                  :page="pagination.page"
                  :server-items-length="pagination.total"
                  :footer-props="{
                    'items-per-page-options': [15],
                    'items-per-page-text': '',
                  }"
                  @update:page="handleUpdatePage"
                  :loading="loading"
                >
                  <template v-slot:item.per_unit="{item}">
                    <span v-if="item.per_unit">₹{{item.per_unit}}</span>
                  </template>
                  <template v-slot:item.festival_only="{item}">
                    <v-icon v-if="item.festival_only">{{ icons.mdiCheckBold }}</v-icon>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="editDonationType(item.id)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmDeletion(item.id)" class="error">
                          <v-list-item-title class="white--text">Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent max-width="400" :value="deleteForm">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteForm = false">No</v-btn>
          <v-btn color="primary" @click="deleteDonationType">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import { mdiCheckBold, mdiDotsVertical, mdiPlus, mdiMagnify } from '@mdi/js';
  import DonationTypeForm from "@/components/donation-type/Form";
  import _ from "lodash";

  export default {
    data() {
      return {
        donationTypes: [],
        queryParams: {
          page: 1,
          search: null,
          type: null,
        },
        pagination: {
          page: 1,
          total: 0,
        },
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Per Unit', value: 'per_unit', align: 'end'},
          {text: 'For Festival Only', value: 'festival_only', align: 'center'},
          {text: 'Actions', value: 'action', align: 'end'},
        ],
        icons: {
          mdiMagnify,
          mdiCheckBold,
          mdiDotsVertical,
          mdiPlus
        },
        editingDonationType: null,
        donationTypeFormStatus: false,
        deletingDonationTypeID: null,
        deleteForm: false,
        loading: false
      }
    },
    computed: {
      selectedFilters() {

        return  {
          search: this.queryParams.search,
          type: this.queryParams.type,
        };
      }
    },
    components: {
      DonationTypeForm,
    },
    watch: {
      queryParams: {
        deep: true,
        async handler() {

          if (this.$router.currentRoute.fullPath !== this.$router.resolve({name: this.$router.currentRoute.name, query: this.queryParams}).route.fullPath) this.$router.replace({query: this.queryParams});
          await this.fetchDonationTypes(this.queryParams);
        }
      },
      donationTypeFormStatus() {

        if (this.donationTypeFormStatus === false) {

          this.fetchDonationTypes(this.queryParams);
          this.editingDonationType = null;
        }
      },
      deleteForm() {

        if (this.deleteForm === false) this.deletingDonationTypeID = null;
      }
    },
    async created() {

      if (Object.entries(this.$router.currentRoute.query).length > 0) {

        this.queryParams = {
          page: this.$router.currentRoute.query.page && this.$router.currentRoute.query.page !== 'undefined' ? parseInt(this.$router.currentRoute.query.page) : 1,
          search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
          type: this.$router.currentRoute.query.type && this.$router.currentRoute.query.type !== 'undefined' ? parseInt(this.$router.currentRoute.query.type) : null,
        };
      } else {

        this.fetchDonationTypes(this.queryParams);
      }
    },
    methods: {
      fetchDonationTypes(params) {

        this.loading = true;
        axiosBackend.get('/donation-types', {params})
          .then((response) => {

            this.donationTypes = response.data.donation_types;
            this.pagination.total = response.data.meta.total;
            this.pagination.page = response.data.meta.current_page;
            this.loading = false;
          });
      },
      debounceSearch: _.debounce(function (value) {

        this.updateFilter(value, 'search')
      }, 500),
      updateFilter(value, field) {

        this.queryParams[field] = value;
        this.queryParams.page = 1;
      },
      handleUpdatePage(page) {

        this.queryParams.page = page;
      },
      editDonationType(id) {

        this.editingDonationType = this.donationTypes.find(item => item.id === id);
        this.donationTypeFormStatus = true;
      },
      confirmDeletion(id) {

        this.deletingDonationTypeID = id;
        this.deleteForm = true;
      },
      deleteDonationType() {

        this.loading = true;
        axiosBackend.delete('/donation-types/' + this.deletingDonationTypeID)
        .then(() => {

          this.fetchDonationTypes(this.queryParams);
          this.deleteForm = false;
          this.loading = false;
        })
        .catch();
      }
    }
  }
</script>

<style scoped>

</style>

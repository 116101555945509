var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('donation-type-form',{attrs:{"show":_vm.donationTypeFormStatus,"donation-type":_vm.editingDonationType},on:{"update:show":function($event){_vm.donationTypeFormStatus=$event}}}),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.donationTypeFormStatus = true}}},[_c('v-icon',{domProps:{"innerHTML":_vm._s(_vm.icons.mdiPlus)}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("Donation Categories")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"10"}},[_c('v-text-field',{staticClass:"app-bar-search flex-grow-0",attrs:{"dense":"","outlined":"","clearable":"","label":"Search","prepend-inner-icon":_vm.icons.mdiMagnify,"value":_vm.selectedFilters.search},on:{"input":_vm.debounceSearch}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"2"}},[_c('v-select',{attrs:{"outlined":"","dense":"","deletable-chips":"","label":"Type","placeholder":"Type","items":[{text: 'All', value: null}, {text: 'For Festival Only', value: 1}, {text: 'Non Festival', value: 0}],"value":_vm.selectedFilters.type},on:{"change":function($event){return _vm.updateFilter($event,'type')}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.donationTypes,"disable-sort":"","hide-default-footer":!_vm.donationTypes.length,"items-per-page":15,"page":_vm.pagination.page,"server-items-length":_vm.pagination.total,"footer-props":{
                  'items-per-page-options': [15],
                  'items-per-page-text': '',
                },"loading":_vm.loading},on:{"update:page":_vm.handleUpdatePage},scopedSlots:_vm._u([{key:"item.per_unit",fn:function(ref){
                var item = ref.item;
return [(item.per_unit)?_c('span',[_vm._v("₹"+_vm._s(item.per_unit))]):_vm._e()]}},{key:"item.festival_only",fn:function(ref){
                var item = ref.item;
return [(item.festival_only)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheckBold))]):_vm._e()]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-n3 mt-n1",attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editDonationType(item.id)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{staticClass:"error",on:{"click":function($event){return _vm.confirmDeletion(item.id)}}},[_c('v-list-item-title',{staticClass:"white--text"},[_vm._v("Delete")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400","value":_vm.deleteForm}},[_c('v-card',[_c('v-card-title',{staticClass:"d-block w-full text-center"},[_vm._v("Confirm")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v("Are you sure you want to delete this record?")]),_c('v-card-actions',{staticClass:"mt-2 text-center d-block"},[_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.deleteForm = false}}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteDonationType}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }